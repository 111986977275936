<script setup>
import {
  Search,
  List,
  Cell
} from 'vant'
import { reactive, ref } from "vue";
import { CopyText } from '@/utils/index'

const props = defineProps();

const keywrodInput = ref("");
const listInfo = reactive({
  list: [],
  pageIndex: 1,
  pageSize: 100,
  loading: false,
  finished: false,
  isError: false
})

// 触发搜索
function onSearch (isInit) {
  listInfo.isError = false
  listInfo.finished = false
  if (!keywrodInput.value) {
    listInfo.list = []
    listInfo.finished = true
    return
  }
  if (isInit) {
    listInfo.list = []
    listInfo.pageIndex = 1
  }
  listInfo.loading = true
  const AMap = window.AMap
  AMap.plugin("AMap.PlaceSearch", () => {
    const autoOptions = {
      city: "全国",
      pageSize: listInfo.pageSize,
      pageIndex: listInfo.pageIndex
    };
    const placeSearch = new AMap.PlaceSearch(autoOptions);
    placeSearch.search(keywrodInput.value, (status, result) => {
      console.log("result=>", result);
      if (result?.info === "OK") {
        const listObj = result?.poiList || {}
        const total = listObj?.count || 0
        listInfo.list = listInfo.list.concat(listObj?.pois || [])
        if (listInfo.pageIndex * listInfo.pageSize >= total) {
          listInfo.finished = true
        } else {
          listInfo.finished = false
          listInfo.pageIndex++
        }
      } else {
        listInfo.finished = true
      }
      listInfo.loading = false
    });
  });
}

// 复制当前cell的地址数据
function copyAddress (item) {
  CopyText(item.address)
}
</script>

<template>
  <div class="search-address">
    <!-- 搜索输入框 -->
    <Search
      class="search"
      v-model="keywrodInput"
      show-action
      clearable
      placeholder="请输入地址关键词"
      background="pink"
      @search="onSearch(true)"
    >
      <template #action>
        <div @click="onSearch(true)">搜索</div>
      </template>
    </Search>

    <!-- 展示列表 -->
    <List
      v-model:loading="listInfo.loading"
      :finished="listInfo.finished"
      v-model:error="listInfo.isError"
      error-text="请求失败，点击重新加载"
      finished-text="没有更多了"
      @load="onSearch(false)"
    >
      <Cell v-for="item in listInfo.list" :key="item.id" :title="item.name" :value="item.address" @click="copyAddress(item)" />
    </List>
  </div>
</template>

<style lang="scss" scoped>
.search-address {
  .search {
    position: sticky;
    top: 0;
    margin-bottom: 1rem;
    z-index: 10;
  }
}
</style>
